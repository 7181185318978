import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { OurMissionComponent } from 'src/app/our-mission/our-mission.component';

const routes: Routes = [
  { path: '', component: AboutUsComponent, pathMatch: 'full' },
  { path: 'privacy', redirectTo: 'privacy-policy' },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'terms', redirectTo: 'terms-and-conditions' },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'contact', redirectTo: 'contact-us' },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'our-mission', redirectTo: 'mission' },
  { path: 'mission', component: OurMissionComponent },
  {
    path: 'services',
    loadChildren: () =>
      import('./services/services.module').then((m) => m.ServicesModule),
  },
  {
    path: 'blog',
    loadChildren: () => import('./blog/blog.module').then((m) => m.BlogModule),
  },
  { path: '404', component: NotFoundComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
